export default function FeeCard({fee}){
    const linkToRedirect = `https://wa.me/573006239961?text=Hola%20me%20gustaria%20recibir%20mas%20informacion%20de%20la%20${fee.group}%2C%20lo%20ideal%20seria%20tomar%20${fee.description}`
    return (
        <div class="fee--card">
            <div class="fee--card-header">
                {fee.isRecommended&&(<div class="fee--recommended"><p>Recomendado</p></div>)} 
                <p>{fee.description}</p>
            </div>
            <div class="fee--card-body">
                <p class="fee--old"><s>{fee.oldFee}</s></p>
                <p class="fee--new"><span>{fee.currentFee}</span></p>
            </div>
            <div class="fee--card-footer">
                <a href={linkToRedirect} target="_blank" rel="noreferrer"><button type="button">Agendar</button></a>
            </div>
        </div>
    )
}