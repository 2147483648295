import instagramIcon from '../assets/icons/instagram-icon.png'
import facebookIcon from '../assets/icons/facebook-icon.png'
import tiktokIcon from '../assets/icons/tiktok-icon.png'
import whatsappIcon from '../assets/icons/whatsapp-icon.png'
import logo from '../assets/img/logoKarolineRojas.png'

export default function MenuMobile({onClose}){
    return (
        <nav className="menu--container" id="menu-container-mobile">           
        <div className="menu--container-overlay">        
        </div>          
        <menu className="menu--container-menu">            
            <span 
                className="material-symbols-outlined close-icon" 
                id="menu--button-close"
                onClick={onClose}>
                close
            </span>            
            <div>
                <img className="menu--logo" src={logo} alt="logo psicologa carolina rojas"/>
            </div>
            <div className="menu--content">
                <ul>
                    <li><a href="#hero" onClick={onClose}>Inicio</a></li>
                    <li><a href="#about-me"  onClick={onClose}>Sobre mi</a></li>
                    <li><a href="#goals"  onClick={onClose}>Mi experiencia</a></li>
                    <li><a href="#opinions" onClick={onClose}>Opiniones</a></li>
                    <li><a href="#fees"  onClick={onClose}>Mis servicios</a></li>                    
                </ul>
            </div>
            <p>Contactame en:</p>
            <div className="menu--social-media">   
                <span className="icon-social-media whatsapp"> 
                <a href="https://wa.me/573006239961" target="_blank" rel="noreferrer">
                        <img src={whatsappIcon} alt="whatsapp icon"/>
                    </a> 
                </span>
                <span className="icon-social-media instagram">
                    <a href="https://www.instagram.com/psi.karolinerojas?igsh=dWEwMHVodXBoM2pj" target="_blank" rel="noreferrer">
                        <img src={instagramIcon} alt="instagram icon"/> 
                    </a>
                </span>
                <span className="icon-social-media facebook">
                    <a href="https://www.facebook.com/profile.php?id=61552424018365&mibextid=ZbWKwL"  target="_blank" rel="noreferrer">
                        <img src={facebookIcon} alt="facebook icon"/> 
                    </a>
                </span>
                <span className="icon-social-media tiktok" >
                    <a href="https://www.tiktok.com/@psi.karolinerojas?_t=8lUyDn9K6zO&_r=1" target="_blank" rel="noreferrer">
                        <img src={tiktokIcon} alt="tiktok icon"/> 
                    </a>
                </span>
            </div>  
        </menu>   
    </nav>
    )
}