
export default function OpinionCard({rateToShow = null, isVisible = false}){  
    const rate = rateToShow
    const classNames = (`opinion-card--container ${isVisible?'active':'inactive'}`)
    return ( 
         (rate !== null && isVisible) &&(
        <div className={classNames} key={rate.id}>
            <div className="opinion-card--header">
                <div className="user--container">
                    {
                        rate.user.gender.toUpperCase() === 'M' 
                        ?
                        <span class="material-symbols-outlined">
                            face_6
                        </span>
                        :
                        <span class="material-symbols-outlined">face_3</span>      
                    }
                                  
                    <span>{rate.user?.name}</span>
                </div>
            </div>
            <div className="opinion-card--body">
                <p><span className="opinion-opinion--quote">" </span>{rate?.opinion}<span className="opinion-opinion--quote"> "</span></p>
            </div>
            <div className="opinion-card--footer" >
                <div className="card-stars-container">
                {
                   rate.grade.map(()=><span class="material-symbols-outlined">grade</span>) 
                }  
                </div>
                                     
            </div>
        </div>)
    
    );
}

