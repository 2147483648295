import { useEffect, useState } from "react"
import './aboutMe.css';

export default function AboutMe({isMobile}){
    const [isShowMoreActive, setIsShowMoreActive] = useState(false)

    useEffect(()=>{
        if(!isMobile){
            setIsShowMoreActive(true) 
        }
    })

    function handleShowMore(){
        setIsShowMoreActive(!isShowMoreActive)
    }

    return(
        <div className="about-me-container">               
            <div className="about-me-container--description">
               {!isMobile&&<h2>Sobre mi</h2>}
                <p>¡Hola! Soy Carolina Rojas, psicóloga graduada de la Universidad Corporación Universitaria Minuto de Dios. Poseo un diplomado en Trastornos Específicos del Aprendizaje en la Primera Infancia y su Intervención en el Ambiente Escolar.</p>
                {isShowMoreActive?<>
                    <p> Con más de un año de experiencia en los ámbitos clínico, organizacional y educativo, mi trayectoria en psicología comenzó con el firme propósito de servir a otros. Me apasiona encontrar las herramientas necesarias para ayudar a mis pacientes desde un enfoque cognitivo-conductual. Realizo acompañamiento terapéutico cada 8 días de manera virtual, revisando los avances y trabajando juntos para alcanzar los objetivos terapéuticos.</p>
                    <p>Creo firmemente en el autoconocimiento y la consciencia como pilares fundamentales para el crecimiento personal. Cada paciente representa un mundo único y especial, donde el poder de transformación reside en el proceso de autoconocimiento y la toma de consciencia.</p>
                    <p>Estoy aquí para acompañarte en tu camino hacia el bienestar emocional y el logro de tus metas. ¡Déjame ser parte de tu historia!. </p>
                    {isMobile&&<span onClick={handleShowMore}>Mostrar menos ...</span>}
                </>: <>
                    <span onClick={handleShowMore}>Mostrar mas ...</span>
                </>}
                
            </div>
        </div>
    )
}