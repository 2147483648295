 import instagramIcon from '../assets/icons/instagram-icon.png'
 import facebookIcon from '../assets/icons/facebook-icon.png'
 import tiktokIcon from '../assets/icons/tiktok-icon.png'

export default function Footer(){
    return(
    <footer>
        {/* <ul>           
            <li><a href="#">Terminos y condiciones</a></li>
        </ul> */}
        <div class="social-media-container">
            <p>Sigueme en:</p>
            <span class="icon-social-media instagram">
                <a href="https://www.instagram.com/psi.karolinerojas?igsh=dWEwMHVodXBoM2pj" target="_blank">
                    <img src={instagramIcon} alt="instagram icon" class="footer-icon"/> 
                </a>
            </span>
            <span class="icon-social-media facebook">
                <a href="https://www.facebook.com/profile.php?id=61552424018365&mibextid=ZbWKwL"  target="_blank">
                    <img src={facebookIcon} alt="facebook icon" class="footer-icon"/> 
                </a>
            </span>
            <span class="icon-social-media tiktok" >
                <a href="https://www.tiktok.com/@psi.karolinerojas?_t=8lUyDn9K6zO&_r=1" target="_blank">
                    <img src={tiktokIcon} alt="tiktok icon" class="footer-icon"/> 
                </a>
            </span>
        </div> 
        <div>
            <p>&copy; 2024 Psicóloga Online.<br></br>Todos los derechos reservados.</p>
        </div>       
    </footer>
    )
}