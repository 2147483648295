export default function Hero(){
    return (
        <div className="hero--container">
            <div className="hero--header">

            </div>
            <div className="hero--container--img">
                
            </div>
            <div  className="hero--container--description">
                <h1>Karoline Rojas</h1>
                <h2>Psicóloga</h2>
                <p>"Acompañándote en el camino hacia tu bienestar emocional."</p>
            </div>
        </div>
    )
}