

async function  getData(range){// Especifica el rango de celdas que quieres leer      
  try {    
    const sheetId = '1vV0MJ9BlGgchSUV7vOCP-G5miOq9wUC-UOy5f87DD10'
    const apiKey = 'AIzaSyDTORVba5IxaLa9WypZcsEOEZibPzt7UYI';  // Opcional si utilizas la API de Google
    const response = await fetch(
       `https://sheets.googleapis.com/v4/spreadsheets/${sheetId}/values/${range}?key=${apiKey}`
      );

      if (!response.ok) {
        throw new Error('Error en la solicitud');
      }

      const result = await response.json();      

    return result
  } catch (error) {
    
  }  
}

export async function getOpinionData(){
  try {    
    const ratesRange = 'opiniones!A2:D50'; 
    const result = await getData(ratesRange)    

    return formmatOpinionToShow(result.values);
  } catch (error) {
    
  }  
}



function formmatOpinionToShow(data = []){  
  const indexName = 0;
  const indexGender = 1;
  const indexOpinion = 2;
  const indexGrade = 3

  let rates = []   

  for(let i=0; i<= data.length -1; i++){       
      let newRate = {
        id:i,
        user: {
            name: data[i][indexName],
            gender: data[i][indexGender]
        },
        opinion: data[i][indexOpinion],
        grade: new Array(parseInt(data[i][indexGrade])).fill('')
      }

      rates.push(newRate)
  }

  return rates;
}


export async function getGoalsData(){
  try {    
    const goalsRange = 'logros!A2:C20'; // Especifica el rango de celdas que quieres leer      
    const result = await getData(goalsRange)  
    return formmatGoalsToShow(result.values);
  } catch (error) {
    
  }  
}

function formmatGoalsToShow(data = []){
  const indexNumber = 0;
  const indexDescription = 1;
  const indexIcon = 2; 

  let goalsAchieved = []   

  for(let i=0; i<= data.length -1; i++){       
      let newGoal = {
        id:i,
        number: data[i][indexNumber],
        description: data[i][indexDescription],
        iconName:  data[i][indexIcon]
      }

      goalsAchieved.push(newGoal)
  }

  return goalsAchieved;
}

export async function getFeesData(){
  try {    
    const feesRange = 'tarifas!A2:F20'; // Especifica el rango de celdas que quieres leer      
    const result = await getData(feesRange)  
    return frommatFeesToShow(result.values);
  } catch (error) {
    
  }  
}

function frommatFeesToShow(data= []){
  const indexGroup = 0;
  const indexGroupDescription = 1;
  const indexDescription = 2;
  const indexOldFee = 3; 
  const indexCurrentFee = 4; 
  const indexIsRecommended = 5;

  let currentFees = []   

  for(let i=0; i<= data.length -1; i++){       
      let newFee = {
        id:i,
        group: data[i][indexGroup],
        groupDescription: data[i][indexGroupDescription],
        description: data[i][indexDescription],
        oldFee:  data[i][indexOldFee],
        currentFee:  data[i][indexCurrentFee],
        isRecommended: (data[i][indexIsRecommended].toLowerCase() === 'si')? true: false,
      }

      currentFees.push(newFee)
  }

  return currentFees;
}