import { useState } from "react"

export default function Menu(){
    const [indexActive, setIndexActive] = useState(0)

    function handleItemActive(indexItem){
        setIndexActive(indexItem)
    }

    return(
        <menu>
            <ul>
                <a href="#hero" onClick={()=>{handleItemActive(0)}}>
                    <li className={indexActive===0?'active':''}>Inicio</li>
                </a>
                <a href="#about-me" onClick={()=>{handleItemActive(1)}}>
                    <li className={indexActive===1?'active':''}>Sobre mi</li>
                </a>
                <a href="#goals" onClick={()=>{handleItemActive(2)}}>
                    <li className={indexActive===2?'active':''}>Mi experiencia</li>
                </a>
                <a href="#opinions" onClick={()=>{handleItemActive(3)}}>
                    <li className={indexActive===3?'active':''}>Opiniones</li>
                </a>
                <a href="#fees" onClick={()=>{handleItemActive(4)}}>
                    <li className={indexActive===4?'active':''}>Mis servicios</li>
                </a>   
            </ul>
        </menu>
    )
}