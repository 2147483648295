import wpIcon from '../assets/img/WhatsApp_icon.png.webp'
export default function WhatsAppIcon(){
    return(
        <div className='contact-icon'>
        {/* <span>Contáctame por</span> */}
            <a href="https://wa.me/573006239961" target="_blank" rel="noreferrer">
                <img src={wpIcon} alt="whatsapp icon" />
            </a>
        </div>
    )
}