export default function GoalCard({goal = null}){
    return (        
        goal !== null&&(
        <div className="card--container">
            <div className="card--content">
                <span class="material-symbols-outlined card--content-icon">{goal.iconName}</span>
                <div>
                    <span className="goal--number">+ {goal.number}</span><br></br>
                    <span>{goal.description}</span>
                </div>
            </div>
        </div>
        )
         
    )

}