import GoalCard from "./goal-card";

export default function GoalsAchieved({goalsAchieved}){
    return (
        <div className="goals--container">
        {
            goalsAchieved.length > 0 
            ? goalsAchieved.map(goal=><GoalCard key={goal.id} goal={goal}></GoalCard>)        
            : <h3>There are not goals to show</h3>  
        }
        </div>  
    )
}