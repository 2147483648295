export default function Accordion({title,children, isOpen, setIsOpen}){
    
    return(<div className="accordion">
        <div className="accordion-header" onClick={setIsOpen}>
            <h3>{title}</h3>
            <span  class="material-symbols-outlined">{isOpen ? "remove" : "add"}</span>
        </div>
        {
        isOpen&&(
        <div className="accordion-content">
            {children}
        </div>)}
    </div>)
}