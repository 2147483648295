import { useState, useEffect } from "react";
import Opinions from "../components/opinions";
import GoalsAchieved from "../components/goals";
import Fees from "../components/fees";
import Section from "../components/section";
import { getOpinionData, getGoalsData, getFeesData } from '../utils/getData';
import Footer from "../components/footer";
import Header from "../components/header";
import AboutMe from "../components/aboutMe";
import Hero from "../components/hero";
import WhatsAppIcon from "../components/whatsappIcon";
import { useMediaQuery } from 'react-responsive';

export default function LandingPage(){
    const isMobile = useMediaQuery({ maxWidth: 810 });
    const [opinions, setOpinions] = useState([])
    const [goals, setGoals] = useState([])
    const [fees, setFees] = useState([])  

    useEffect(()=>{           
        getOpinionData()
        .then(opinionsData=>{
            setOpinions(opinionsData)
        })

        getGoalsData()
        .then((goalsData)=>{
            
            setGoals(goalsData)
        })

        getFeesData()
        .then((feesData)=>{
            setFees(feesData)
        })
       
    },[])

    return (
    <>
    <Header isMobile={isMobile}></Header>  
    <div className="landing-page--container">         
        {isMobile?(
        <>
            <Section id={'hero'}>            
                <Hero></Hero>
            </Section>         
            <Section id={'about-me'} title={'Sobre mi'}>                      
                <AboutMe isMobile={isMobile}></AboutMe>
            </Section>
        </>):(        
            <Section id={'hero'}>   
                <Hero></Hero>                           
                <AboutMe isMobile={isMobile}></AboutMe>  
            </Section>       
        )

        }
        <Section id={'goals'} title={'Mi experiencia'}>                  
            <GoalsAchieved goalsAchieved={goals}></GoalsAchieved>
        </Section>
        <Section id={'opinions'} title={'Opiniones'}>            
            <Opinions opinions={opinions} isMobile={isMobile}></Opinions>
        </Section>
        <Section id={'fees'} title={'Mis servicios'}>            
            <Fees fees={fees}></Fees>       
        </Section>
        <WhatsAppIcon></WhatsAppIcon>
    </div>
    <Footer></Footer>
    </>)
}