
import { useState } from 'react';
import OpinionCard from './opinion-card';

import Gallery from './gallery';
let currentInterval = null  

export default function Opinions({opinions, isMobile}){       
    const [selectedOpinionId, setSelectedOpinionId] = useState(0)  
    
    clearInterval(currentInterval)

    currentInterval = setInterval(()=>{
        let nextOpinion = 0
        if(selectedOpinionId === opinions.length -1){
            nextOpinion= 0
        }else{
            nextOpinion= selectedOpinionId + 1
        }
        
        setSelectedOpinionId(nextOpinion)
    },8000)
    

    function handlePrevClick(){
        setSelectedOpinionId(()=>{
            if(selectedOpinionId === 0){
                return 0
            }else{
                return selectedOpinionId - 1
            }
        })
        console.log(selectedOpinionId)
    }

    function handleNextClick(){
        setSelectedOpinionId(()=>{
            if(selectedOpinionId === opinions.length -1){
                return opinions.length -1
            }else{
                return  selectedOpinionId + 1
            }
           
        })
        console.log(selectedOpinionId)
    }
    return(
    <> 
         
        {
            opinions.length > 0 
            ?   isMobile ?(
                <Gallery 
                    handlePrevClick={handlePrevClick} 
                    handleNextClick={handleNextClick} 
                    currentItem={selectedOpinionId}
                    setCurrentItem={setSelectedOpinionId}> 
                    {
                        opinions.map((opinion)=>(
                        <OpinionCard 
                            rateToShow={opinion} 
                            key={opinion.id}
                            isVisible={ opinion.id === selectedOpinionId}>
                        </OpinionCard>))
                    }
                </Gallery>):
                (<div className='opinios-grid'> 
                    {opinions.map((opinion)=>(
                    <OpinionCard 
                        rateToShow={opinion} 
                        key={opinion.id}
                        isVisible={true}>

                    </OpinionCard>))}
                </div>)
            : <h1>There are no opinios to show</h1>
        }
           
        
    </>)

}


