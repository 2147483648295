import './App.css';
import LandingPage from './pages/landinpage';

function App() {
  return (
   <LandingPage></LandingPage>
  );
}

export default App;
