import { useState } from 'react'
import logo from '../assets/img/sign.png'
import MenuMobile from './menu-mobile'
import Menu from './menu'

export default function Header({isMobile}){
    const [isMenuMobileVisible, setIsMenuMobileVisible] = useState(false)

    const className = isMobile?'header--logo header--logo-small-screens':'header--logo header--logo-big-screens'

    function handleOpenMenu(){
        setIsMenuMobileVisible(true)
    }

    function handleCloseMenu(){
        setIsMenuMobileVisible(false)
    }
    return(<>
        <header>
            <div className={className}>
                <img src={logo} alt="logo psicologa karoline rojas" />
            </div>
            {
                !isMobile&&(<Menu></Menu>)
            }
            {
                isMobile&&(
                <div className="header-icon--container">
                    <span class="material-symbols-outlined" onClick={handleOpenMenu}>
                        menu
                    </span>
                </div>)
            }
        </header>
        {isMenuMobileVisible&&(<MenuMobile onClose={handleCloseMenu}></MenuMobile>)}
        </>
    )
}