import { useState } from "react"
import Accordion from "./accordion"
import FeeCard from "./fee-card"
export default function Fees({fees=[]}){
    const [currentGroupExpandedId, setCurrentExpandedGroupId] = useState(0)
    const groups = [...new Set(fees.map(fee=>fee.group))]
    const groupDescription = [...new Set(fees.map(fee=>fee.groupDescription))]

    function handleAccordionVisualization(groupId){
        if(groupId === currentGroupExpandedId){
            groupId = -1
        }
        setCurrentExpandedGroupId(groupId)
    }

    return(
        groups.length>0&&(
            groups.map((group, index)=><>
            <Accordion 
                title={group}
                isOpen={currentGroupExpandedId === index}
                setIsOpen={()=>{
                    handleAccordionVisualization(index)
                }}
            >            
            <p className="content-description">{groupDescription.at(index)}</p>
            <div className="fees--container">
                {fees.map((fee)=>{
                    if(fee.group===group){
                      return  <FeeCard fee={fee}></FeeCard>
                     }
                    })}
             </div>
             </Accordion>
            </>)
        )
  )
}