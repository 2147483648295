
export default function Gallery({children, currentItem, setCurrentItem ,handlePrevClick,handleNextClick}){
   
    return (
        <>
            <div className="gallery-container">
                <div className="gallery-container--button">
                    <button onClick={handlePrevClick} className="gallery--button button-left">
                        <span class="material-symbols-outlined">arrow_back_ios</span>
                    </button>
                </div>
                <div className="gallery-container--content">
                    {children}
                </div>
                <div className="gallery-container--button">
                    <button  onClick={handleNextClick} className="gallery--button button-right">
                        <span  class="material-symbols-outlined">arrow_forward_ios</span>
                    </button>
                </div>
            </div>
            <div className="gallery-spinner">                
                {children.length > 0 && ( children.map((child)=>{
                     const iconName = currentItem.toString() === child.key.toString() ? 'radio_button_checked':'radio_button_unchecked'

                     return (
                        <span class="material-symbols-outlined gallery--button" key={child.key} onClick={()=>{
                            setCurrentItem(parseInt(child.key))
                        }}>
                            {iconName}
                        </span> 
                    )                  
                }
                ))}
            </div>
        </>
    );
}